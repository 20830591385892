<template>
  <div>
    <b-card title="Job Details">
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th style="width: 220px">
                  Job name
                </th>
                <td>
                  {{ job.jobName }}
                </td>
              </tr>
              <tr v-if="configuredJob">
                <th>Job type</th>
                <td>{{ configuredJob.type | veeamJobTypeDisplayName(configuredJob.platform) }}</td>
              </tr>
              <tr v-if="configuredJob">
                <th>Compression level</th>
                <td>{{ configuredJob.compressionLevel | veeamJobCompressionLevelDisplayName }}</td>
              </tr>
              <tr v-if="configuredJob">
                <th>Backup Repository</th>
                <td>{{ getBackupRepositoryTextForJobById(configuredJob.repositoryId) }}</td>
              </tr>
              <tr v-if="job.firstJobSessionStart">
                <th>First job session started on</th>
                <td>
                  {{ job.firstJobSessionStart | formatDateTime }}
                  <feather-icon
                    v-b-popover.hover="'The actual date of the first job session of this job may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
              <tr v-if="job.lastJobSessionStart">
                <th>Last job started on</th>
                <td>
                  {{ job.lastJobSessionStart | formatDateTime }}
                </td>
              </tr>
              <tr>
                <th>Job Sessions</th>
                <td>
                  {{ job.jobSessionCount }}
                  <feather-icon
                    v-b-popover.hover="'The actual number of job sessions of this job may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
              <tr v-if="configuredJob">
                <th>Job description</th>
                <td>{{ configuredJob.description }}</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
            :timezone="asup.timezone"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-overlay :show="isJobsLoading">
      <b-card
        :header="jobCardHeaderTitle"
      >
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Status</th>
                <th>Start time</th>
                <th>End time</th>
                <th>Duration</th>
                <th>Succeeded</th>
                <th>Warning</th>
                <th>Failed</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <template v-for="(jobSession, jobSessionIndex) in sortedJobSessions">
                <tr :key="jobSessionIndex">
                  <td>
                    <div class="row p-0 m-0">
                      <div class="col-6 p-0 m-0">
                        <feather-icon
                          :icon="jobSession.showDetails === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                          class="cursor-pointer float-left"
                          @click="toggleDetails(jobSession)"
                        />
                      </div>
                      <div class="col-6 p-0 m-0">
                        <JobStatusIcon
                          :status="jobSession.calculatedStatus"
                          class="float-left"
                        />
                      </div>
                    </div>
                  </td>
                  <td>{{ jobSession.start | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ jobSession.end | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ jobSession.duration | formatClrTimeSpan }}</td>
                  <td>{{ jobSession.clientSuccessCount }} </td>
                  <td>{{ jobSession.clientWarningCount }} </td>
                  <td>{{ jobSession.clientFailedCount }} </td>
                  <td>
                    <b-button
                      size="sm"
                      :to="{ name: 'veeam-detail.job-session-detail', params: { jobName: jobSession.jobName,
                                                                                jobSessionId: jobSession.id} }"
                    >
                      Detail
                    </b-button>
                  </td>
                </tr>
                <tr
                  v-if="jobSession.showDetails"
                  :key="`${jobSessionIndex}_detail`"
                >
                  <td colspan="4">
                    <div class="row">
                      <div class="col">
                        <dl>
                          <dt>Avg. speed</dt>
                          <dd>{{ jobSession.avgSpeed | prettyBytes({binary: true}) }}</dd>

                          <dt>Read avg. size</dt>
                          <dd>{{ jobSession.readAvgSize | prettyBytes({binary: true}) }}</dd>

                          <dt>Processed objects</dt>
                          <dd>{{ jobSession.processedObjects }}</dd>

                          <dt>Total objects</dt>
                          <dd>{{ jobSession.totalObjects }}</dd>
                        </dl>
                      </div>
                      <div class="col">
                        <dl>
                          <dt>Read size</dt>
                          <dd>{{ jobSession.readSize | prettyBytes({binary: true}) }}</dd>

                          <dt>Processed size</dt>
                          <dd>{{ jobSession.processedSize | prettyBytes({binary: true}) }}</dd>

                          <dt>Total size</dt>
                          <dd>{{ jobSession.totalSize | prettyBytes({binary: true}) }}</dd>

                          <dt>Stored size</dt>
                          <dd>{{ jobSession.storedSize | prettyBytes({binary: true}) }}</dd>

                          <dt>Total used size</dt>
                          <dd>{{ jobSession.totalUsedSize | prettyBytes({binary: true}) }}</dd>
                        </dl>
                      </div>
                      <div class="col">
                        <dl>
                          <dt>Reason</dt>
                          <dd>{{ jobSession.reason }}</dd>
                        </dl>
                      </div>
                    </div>

                  </td>
                  <td />
                  <td style="vertical-align: top">
                    <ul>
                      <li
                        v-for="(client, clientIndex) in processClients(jobSessionIndex, 'warningClients')"
                        :key="clientIndex"
                      >
                        <b-link :to="{ name: 'veeam-detail.client-detail', params: { clientName: client.clientName } }">
                          {{ client.clientName }}
                        </b-link>
                        <feather-icon
                          v-if="client.notSeenBefore"
                          v-b-popover.hover="'Client did not report a warning on the job before.'"
                          class="ml-1"
                          icon="AlertCircleIcon"
                        />
                      </li>
                    </ul>
                  </td>
                  <td style="vertical-align: top">
                    <ul>
                      <li
                        v-for="(client, clientIndex) in processClients(jobSessionIndex, 'failedClients')"
                        :key="clientIndex"
                      >
                        <b-link :to="{ name: 'veeam-detail.client-detail', params: { clientName: client.clientName } }">
                          {{ client.clientName }}
                        </b-link>
                        <feather-icon
                          v-if="client.notSeenBefore"
                          v-b-popover.hover="'Client did not report an error on the job before.'"
                          class="ml-1"
                          icon="AlertCircleIcon"
                        />
                      </li>
                    </ul>
                  </td>
                  <td />
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, VBPopover, BFormCheckbox, BForm, BFormGroup, BOverlay, BButton, BLink,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import VeeamService from '@/service/veeam.service'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BOverlay,
    BButton,
    BLink,
    DateRangePicker,
    JobStatusIcon,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      job: {},
      configuredJob: {},
      timeRange: {
        startDate: moment().subtract(7, 'days').toDate(),
        endDate: new Date(),
      },
      isJobsLoading: false,
      jobSessions: [],
      onlyErrors: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    jobName() {
      return this.$route.params.jobName
    },
    jobCardHeaderTitle() {
      return `Job sessions between ${this.$options.filters.formatDate(this.timeRange.startDate)} and ${this.$options.filters.formatDate(this.timeRange.endDate)}`
    },
    statusFilter() {
      if (this.onlyErrors) {
        return ['', 10, 20, 30] // filter for status undefined, active, warning and failed
      }
      return null
    },
    sortedJobSessions() {
      return this.jobSessions
        .concat()
        .sort((a, b) => (a.start > b.start ? -1 : 1))
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment(this.$route.query.from).toDate(),
        endDate: moment(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadJobs)
    this.$watch('onlyErrors', this.loadJobs)
  },
  mounted() {
    this.loadData()
    this.loadJobs()
  },
  methods: {
    getBackupRepositoryTextForJobById(backupRepositoryId) {
      if (!backupRepositoryId) {
        return null
      }

      const backupRepository = this.asup.details.backupRepositories.find(x => x.id === backupRepositoryId)
      if (backupRepository) {
        return `${backupRepository.name} (${backupRepository.path})`
      }

      return null
    },
    loadData() {
      VeeamService.getJobAsync(this.asup.id, this.jobName, { disableTenantFilter: true })
        .then(result => {
          this.job = result
          this.configuredJob = this.asup.details.configuredJobs.find(x => x.id === this.job.jobId)
        })
    },
    loadJobs() {
      this.isJobsLoading = true
      VeeamService.getJobSessionListAsync(this.asup.id, this.jobName, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        status: this.statusFilter,
      }, { disableTenantFilter: true })
        .then(result => {
          result.items.forEach(job => {
            if (job.calculatedJobStatus === 30) {
              // eslint-disable-next-line no-param-reassign
              job.showDetails = true
            }
          })

          this.jobSessions = result.items
        })
        .finally(() => {
          this.isJobsLoading = false
        })
    },
    toggleDetails(jobSession) {
      // eslint-disable-next-line no-param-reassign
      this.$set(jobSession, 'showDetails', !jobSession.showDetails)
    },
    processClients(jobSessionIndex, clientListField) {
      const clients = this.sortedJobSessions[jobSessionIndex][clientListField]

      // sort by string
      const sortedClientNames = clients
        .concat()
        .sort((a, b) => (a > b ? 1 : -1))

      const result = sortedClientNames.map(x => ({
        clientName: x,
      }))

      // check if a client is in list which was not in the list on the day before
      if (jobSessionIndex < this.sortedJobSessions.length - 1) {
        const clientsBefore = this.sortedJobSessions[jobSessionIndex + 1][clientListField]

        for (let i = 0; i < result.length; i += 1) {
          if (!clientsBefore.some(c => c === result[i].clientName)) {
            result[i].notSeenBefore = true
          }
        }
      }

      return result
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
